export const FLOW_DATA = {
    verticals: {
        titleText: 'Search for:',
        buttons: [
            {
                icon: ['left', 'fal', 'credit-card'],
                text: 'Credit Cards',
                color: 'blue',
                value: 'credit_cards'
            },
            {
                icon: ['left', 'fal', 'hand-holding-usd'],
                text: 'Personal Loans',
                color: 'light_blue',
                value: 'personal_loans'
            },
            {
                icon: ['left', 'fal', 'home-heart'],
                text: 'Home Loans',
                color: 'red',
                value: 'home_loans'
            },
            {
                icon: ['left', 'fal', 'car'],
                text: 'Auto Loans',
                color: 'dark_blue',
                value: 'auto_loans'
            },
        ]
    },
    credit_cards: {
        titleText: 'Select a type of Credit Card:',
        buttons: [
            {
                icon: ['left', 'fal', 'percent'],
                text: 'Low Interest',
                color: 'blue',
                value: 'low_interest'
            },
            {
                icon: ['left', 'fal', 'money-bill-wave'],
                text: 'Cash Back',
                color: 'light_blue',
                value: 'cash_back'
            },
            {
                icon: ['left', 'fal', 'gift'],
                text: 'Reward',
                color: 'red',
                value: 'rewards'
            },
            {
                icon: ['left', 'fal', 'user-graduate'],
                text: 'Student',
                color: 'dark_blue',
                value: 'student'
            },
            {
                icon: ['left', 'fal', 'plane-departure'],
                text: 'Travel',
                color: 'blue',
                value: 'travel'
            },
            {
                icon: ['left', 'fal', 'chart-line'],
                text: 'Credit Building',
                color: 'light_blue',
                value: 'credit_building'
            },
            {
                icon: ['left', 'fal', 'briefcase'],
                text: 'Business',
                color: 'red',
                value: 'business'
            },
            {
                icon: ['left', 'fal', 'exchange'],
                text: 'Balance Transfer',
                color: 'dark_blue',
                value: 'balance_transfer'
            },
            {
                icon: ['left', 'fal', 'dollar-sign'],
                text: 'Prepaid',
                color: 'blue',
                value: 'prepaid'
            },
        ]
    },
    auto_loans: {
        titleText: 'Select your desired type of Automobile:',
        buttons: [
            {
                icon: ['left', 'fal', 'cars'],
                text: 'Car',
                color: 'blue',
                value: 'car',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'truck-monster'],
                text: 'SUV',
                color: 'light_blue',
                value: 'suv',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'truck-pickup'],
                text: 'Truck',
                color: 'red',
                value: 'truck',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'shuttle-van'],
                text: 'Van/Minivan',
                color: 'dark_blue',
                value: 'van',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'balance-scale-right'],
                text: 'Not Sure',
                color: 'blue',
                value: 'not_sure',
                crumb: ''
            },
        ]
    },
    personal_loans: {
        titleText: 'Select a Loan Purpose:',
        buttons: [
            {
                icon: ['left', 'fal', 'lightbulb-dollar'],
                text: 'Debt Consolidation',
                color: 'blue',
                value: 'debt_consolidation',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'money-check-edit-alt'],
                text: 'Paying Bills',
                color: 'light_blue',
                value: 'pay_bills',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'shopping-cart'],
                text: 'Make a Purchase',
                color: 'red',
                value: 'make_purchase',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'hands-usd'],
                text: 'Emergency Cash',
                color: 'dark_blue',
                value: 'emergency_cash',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'landmark-alt'],
                text: 'Paying Taxes',
                color: 'blue',
                value: 'pay_taxes',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'search-dollar'],
                text: 'Other Reasons',
                color: 'light_blue',
                value: 'other',
                crumb: ''
            },
        ]
    },
    home_loans: {
        titleText: 'Select a type of Home Loan:',
        buttons: [
            {
                icon: ['left', 'fal', 'sort-size-down'],
                text: 'Refinance',
                color: 'blue',
                value: 'refinance',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'donate'],
                text: 'Lease to Own',
                color: 'light_blue',
                value: 'lease_to_own',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'gopuram'],
                text: 'HELOC',
                color: 'red',
                value: 'heloc',
                crumb: ''
            },
            {
                icon: ['left', 'fal', 'shopping-cart'],
                text: 'Purchase',
                color: 'dark_blue',
                value: 'purchase',
                crumb: ''
            },
        ]
    },
    debt_types: {
        titleText: 'Your primary type of Debt is:',
        buttons: [
            {
                icon: ['left', 'fal', 'credit-card'],
                text: 'Credit Cards',
                color: 'blue',
                value: 'credit_card'
            },
            {
                icon: ['left', 'fal', 'hand-holding-usd'],
                text: 'Personal Loans',
                color: 'light_blue',
                value: 'personal_loan'
            },
            {
                icon: ['left', 'fal', 'heart-rate'],
                text: 'Medical Bills',
                color: 'red',
                value: 'medical'
            },
            {
                icon: ['left', 'fal', 'user-graduate'],
                text: 'Student Loans',
                color: 'dark_blue',
                value: 'student_loan'
            },
            {
                icon: ['left', 'fal', 'car'],
                text: 'Auto Loan',
                color: 'blue',
                value: 'auto_loan'
            },
            {
                icon: ['left', 'fal', 'file-invoice-dollar'],
                text: 'Other Debts',
                color: 'light_blue',
                value: 'other'
            },
        ]
    },
    debt_amounts: {
        titleText: 'Your total Amount of Debt is:',
        buttons: [
            {
                icon: ['left', 'fal', 'coin'],
                text: 'Less than $15,000',
                color: 'blue',
                value: '<15000'
            },
            {
                icon: ['left', 'fal', 'coins'],
                text: '$15,000 - $30,000',
                color: 'light_blue',
                value: '15000-30000'
            },
            {
                icon: ['left', 'fal', 'money-bill-wave'],
                text: '$30,000 - $50,000',
                color: 'red',
                value: '30000-50000'
            },
            {
                icon: ['left', 'fal', 'sack-dollar'],
                text: 'More than $50,000',
                color: 'dark_blue',
                value: '>50000'
            },
        ]
    },
}

export const mNet_window = 
    `<script src='//csearchclub-a.akamaihd.net/dsi.js?cid=8CU7INKUC' async='async'>
        window._mNHandle = window._mNHandle || {};
        window._mNHandle.queue = window._mNHandle.queue || [];
        si_versionId = "3111299";
        si_chnm = " "; // Used to specify the channel name
        si_chnm2 = " "; // Used to specify the channel 2 name
        si_chnm3 = " "; // Used to specify the channel 3 name
        si_misc = {};
        si_misc.query = 'Personal Loan';
    </script>`;