import React from 'react';

const Wall = () => {  
    return(
        <div>
            Wall
        </div>
    )
};

export default Wall;