import {library} from "@fortawesome/fontawesome-svg-core";

import {
    faBars,
    faArrowAltRight,
    faCreditCard,
    faHandHoldingUsd,
    faHomeHeart,
    faCar,
    faPercent,
    faDollarSign,
    faMoneyBillWave,
    faGift,
    faPlaneDeparture,
    faChartLine,
    faBriefcase,
    faExchange,
    faTruckPickup,
    faTruckMonster,
    faShuttleVan,
    faUserGraduate,
    faBalanceScaleRight,
    faLightbulbDollar,
    faMoneyCheckEditAlt,
    faShoppingCart,
    faHandsUsd,
    faSearchDollar,
    faLandmarkAlt,
    faGopuram,
    faSortSizeDown,
    faFileInvoiceDollar,
    faSackDollar,
    faCars,
    faDonate,
    faHeartRate,
    faCoin,
    faCoins,
    faAngleDoubleRight,
    faChevronDoubleRight,
    faThumbsUp,
    faBlog,
    faTimes,
} from '@fortawesome/pro-light-svg-icons';

function initFontAwesome() {
    library.add(
        faBars,
        faArrowAltRight,
        faCreditCard,
        faHandHoldingUsd,
        faHomeHeart,
        faCar,
        faPercent,
        faDollarSign,
        faMoneyBillWave,
        faGift,
        faUserGraduate,
        faPlaneDeparture,
        faChartLine,
        faBriefcase,
        faExchange,
        faCars,
        faTruckPickup,
        faTruckMonster,
        faShuttleVan,
        faBalanceScaleRight,
        faLightbulbDollar,
        faMoneyCheckEditAlt,
        faShoppingCart,
        faHandsUsd,
        faSearchDollar,
        faLandmarkAlt,
        faGopuram,
        faDonate,
        faSortSizeDown,
        faHeartRate,
        faFileInvoiceDollar,
        faCoin,
        faCoins,
        faSackDollar,
        faAngleDoubleRight,
        faChevronDoubleRight,
        faThumbsUp,
        faBlog,
        faTimes,
    );
}

export default initFontAwesome;